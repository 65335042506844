import { callMethod, callBatch } from "./bitrix24ApiServices"

const getCompanies = async (filters) => {
    const params = {
        order: { DATE_CREATE: "DESC" },
        filter: {
            "=ASSIGNED_BY_ID": filters.employees,
            COMPANY_TYPE: "CUSTOMER",
        },
        select: ["ID", "TITLE", "UF_CRM_1629897114", "ASSIGNED_BY_ID"],
    };
    return await callBatch('crm.company.list', params)
}

const getContacts = async (companies_ids) => {
    const params = {
        order: { "DATE_CREATE": "DESC" },
        filter: {
            "=COMPANY_ID": companies_ids
        },
        select: ["ID", "COMPANY_ID", "ASSIGNED_BY_ID"]
    };
    return await callBatch('crm.contact.list', params)
}

const getDials = async (companies_ids, filters) => {
    const params = {
        order: { "DATE_CREATE": "DESC" },
        filter: {
            '>=DATE_CREATE': filters.dateStart,
            '<=DATE_CREATE': filters.dateFinish,
            "=COMPANY_ID": companies_ids
        },
        select: ["ID", "TITLE", "COMPANY_ID", "OPPORTUNITY", "DATE_CREATE"]
    };
    return await callBatch('crm.deal.list', params)
}

const getBissinessTrips = async (companies_ids, filters) => {
    const params = {
        'IBLOCK_TYPE_ID': 'lists',
        'IBLOCK_ID': '73',
        'ELEMENT_ORDER': { "PROPERTY_381": "DESC" },
        'FILTER': {
            '=PROPERTY_375': companies_ids,
            '>=PROPERTY_381': filters.dateStart,
            '<=PROPERTY_381': filters.dateFinish
        }
    };
    return await callBatch('lists.element.get', params)
}

const getActivitiesCompanies = async (companies_ids, filters) => {
    let activities = []
    const params = {
        order: { "CREATED": "DESC" },
        filter:
        {
            "OWNER_TYPE_ID": 4,
            "PROVIDER_TYPE_ID": ['EMAIL', 'MEETING'],
            "=OWNER_ID": companies_ids,
            '>=CREATED': filters.dateStart,
            '<=CREATED': filters.dateFinish
        },
        select: ["OWNER_ID", "OWNER_TYPE_ID", "CREATED", "PROVIDER_TYPE_ID", "DIRECTION"]
    };
    activities = await callBatch('crm.activity.list', params)
    const paramsCall = {
        "SORT": "CALL_START_DATE",
        "ORDER": "DESC",
        "FILTER":
        {
            "CALL_FAILED_CODE": 200,
            "CRM_ENTITY_TYPE": 'COMPANY',
            "=CRM_ENTITY_ID": companies_ids,
            '>=CALL_START_DATE': filters.dateStart,
            '<=CALL_START_DATE': filters.dateFinish
        }
    };
    const calls = await callBatch('voximplant.statistic.get', paramsCall)
    for (let call of calls) {
        activities.push({
            OWNER_ID: call.CRM_ENTITY_ID,
            CREATED: call.CALL_START_DATE,
            OWNER_TYPE_ID: 4,
            PROVIDER_TYPE_ID: 'CALL',
            DIRECTION: call.CALL_TYPE
        });
    }
    return activities
}

const getActivitiesContacts = async (contacts_ids, filters) => {
    let activities = []
    const params = {
        order: { "CREATED": "DESC" },
        filter:
        {
            "OWNER_TYPE_ID": 3,
            "PROVIDER_TYPE_ID": ['EMAIL', 'MEETING'],
            "=OWNER_ID": contacts_ids,
            '>=CREATED': filters.dateStart,
            '<=CREATED': filters.dateFinish
        },
        select: ["OWNER_ID", "OWNER_TYPE_ID", "CREATED", "PROVIDER_TYPE_ID", "DIRECTION"]
    };
    activities = await callBatch('crm.activity.list', params)

    const paramsCall = {
        "SORT": "CALL_START_DATE",
        "ORDER": "DESC",
        "FILTER":
        {
            "CALL_FAILED_CODE": 200,
            "CRM_ENTITY_TYPE": 'CONTACT',
            "=CRM_ENTITY_ID": contacts_ids,
            '>=CALL_START_DATE': filters.dateStart,
            '<=CALL_START_DATE': filters.dateFinish
        }
    };
    const calls = await callBatch('voximplant.statistic.get', paramsCall)
    for (let call of calls) {
        activities.push({
            OWNER_ID: call.CRM_ENTITY_ID,
            CREATED: call.CALL_START_DATE,
            OWNER_TYPE_ID: 3,
            PROVIDER_TYPE_ID: 'CALL',
            DIRECTION: call.CALL_TYPE
        });
    }
    return activities
}

const getActivitiesDials = async (dials_ids, filters) => {
    let activities = []
    const params = {
        order: { "CREATED": "DESC" },
        filter:
        {
            "OWNER_TYPE_ID": 2,
            "PROVIDER_TYPE_ID": ['EMAIL', 'MEETING'],
            "=OWNER_ID": dials_ids,
            '>=CREATED': filters.dateStart,
            '<=CREATED': filters.dateFinish
        },
        select: ["OWNER_ID", "OWNER_TYPE_ID", "CREATED", "PROVIDER_TYPE_ID", "DIRECTION"]
    };
    activities = await callBatch('crm.activity.list', params)
    const paramsCall = {
        "SORT": "CALL_START_DATE",
        "ORDER": "DESC",
        "FILTER":
        {
            "CALL_FAILED_CODE": 200,
            "CRM_ENTITY_TYPE": 'DIAL',
            "=CRM_ENTITY_ID": dials_ids,
            '>=CALL_START_DATE': filters.dateStart,
            '<=CALL_START_DATE': filters.dateFinish
        }
    };
    const calls = await callBatch('voximplant.statistic.get', paramsCall)
    for (let call of calls) {
        activities.push({
            OWNER_ID: call.CRM_ENTITY_ID,
            CREATED: call.CALL_START_DATE,
            OWNER_TYPE_ID: 2,
            PROVIDER_TYPE_ID: 'CALL',
            DIRECTION: call.CALL_TYPE
        });
    }
    return activities
}

const getFieldGroup = async (id) => {
    const groups = await callMethod('crm.company.userfield.get', { id })
    if (groups && groups.LIST) {
        const colors = ['green', 'blue', 'brown', 'orange', 'yellow', 'pink', 'red', 'grey', 'purple', 'darkgreen', 'darkcyan']
        let results = []
        groups.LIST.forEach((group, i) => {
            results.push({
                id: group.ID,
                name: group.VALUE,
                color: colors[i]
            })
        });
        return results
    } else {
        return []
    }
}

const getCommercialOffers = async (companies_ids, filters) => {
    const params = {
        order: { "DATE_CREATE": "DESC" },
        filter: {
            "=COMPANY_ID": companies_ids,
            '>=DATE_CREATE': filters.dateStart,
            '<=DATE_CREATE': filters.dateFinish,
            '=STATUS_ID': ['SENT', 'APPROVED']
        },
        select: ["DATE_CREATE", "STATUS_ID", "OPPORTUNITY", "COMPANY_ID"]
    };
    return await callBatch('crm.quote.list', params)
}

const getTasks = async (companies_ids, filters) => {
    const ids = companies_ids.map((id) => `CO_${id}`);
    const params = {
        order: { "CREATED_DATE": "DESC" },
        filter: {
            "UF_CRM_TASK": ids,
            '>=CREATED_DATE': filters.dateStart,
            '<=CREATED_DATE': filters.dateFinish,
            '=REAL_STATUS': [1, 2, 3, 4]
        },
        select: ["STATUS", "CREATED_DATE", "UF_CRM_TASK"]
    };
    return await callBatch('tasks.task.list', params)
}

export {
    getCompanies,
    getContacts,
    getDials,
    getBissinessTrips,
    getActivitiesCompanies,
    getActivitiesContacts,
    getActivitiesDials,
    getFieldGroup,
    getCommercialOffers,
    getTasks
}