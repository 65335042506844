import { BX24 } from "bx24";
import axios from "axios";
import qs from "qs";

const urlParams = new URLSearchParams(window.location.search);
axios.defaults.baseURL = `https://${urlParams.get("DOMAIN")}/rest/`
const bx24 = new BX24();
const getAuth = async () => await bx24.getAuth()
const throttle = 2
let lastRequestTime = 0;

const callMethod = async (method, params = {}) => {
    const auth = await getAuth()
    const { data } = await axios.post(method, { ...params }, {
        params: {
            auth: auth.ACCESS_TOKEN
        }
    })
    if (data.total > 50) {
        let start = 50
        let results = [...data.result]
        while (start <= data.total) {
            await throttleCall();
            const { data: response } = await axios.post(method, { ...params, start }, {
                params: {
                    auth: auth.ACCESS_TOKEN
                }
            })
            if (!response || response.length == 0) {
                break;
            }
            start += 50
            results = [...results, ...response.result]
        }
        return results
    } else {
        return data?.result
    }
}
const callBatch = async (method, params = {}) => {
    const auth = await getAuth()
    const { data } = await axios.post(method, { ...params }, {
        params: {
            auth: auth.ACCESS_TOKEN
        }
    })
    if (data && data.total > 50) {
        await throttleCall();
        let results = []
        if(method === 'tasks.task.list'){
            results = [...results, ...data.result.tasks]
        } else {
            results = [...results, ...data.result]
        }
        const total = data.total
        const count2500 = Math.ceil(total / 2500)

        let start = 50
        for (let i1 = 0; i1 < count2500; i1++) {
            let cmd = {}
            for (let i2 = 0; i2 < 50; i2++) {
                const paramsBatch = qs.stringify(
                    { ...params, start }
                );
                start += 50
                cmd[`${method}_${i1}_${i2}`] = `${method}?${paramsBatch}`
                if (start >= total) {
                    break
                }
            }

            const { data } = await axios.post('batch',
                { 'halt': 0, cmd },
                { params: { auth: auth.ACCESS_TOKEN } }
            )

            if (data && data.result) {
                for (const key in data.result.result) {
                    if (data.result.result[key]){
                       if(method === 'tasks.task.list'){
                            results = [...results, ...data.result.result[key].tasks]
                        } else {
                            results = [...results, ...data.result.result[key]]
                        } 
                    }                   
                }
            }

            if (start >= total) {
                break
            }
        }
        return results
    }
    if(method === 'tasks.task.list'){
        return data.result.tasks
    } else {
        return data.result
    }
    
}
// Обеспечивает троттлинг запросов к API Битрикс24 на заданном уровне
function throttleCall() {
    return new Promise(resolve => {
        let timeout = Math.round(lastRequestTime + 1e3 * (1 / throttle) - Date.now());
        if (timeout <= 0) {
            lastRequestTime = Date.now();
            return resolve();
        }
        setTimeout(() => {
            lastRequestTime = Date.now();
            return resolve();
        }, timeout);
    });
}

export { callMethod, callBatch }