import {
    getCompanies,
    getContacts,
    getDials,
    getBissinessTrips,
    getActivitiesCompanies,
    getActivitiesContacts,
    getActivitiesDials,
    getFieldGroup,
    getCommercialOffers,
    getTasks
} from './getTableData';
import dayjs from 'dayjs';

export default async function getResultData(filters, employees) {
    let results = []
    let contacts = []
    let dials = []
    let business_trips = []
    let activities = []
    let companies_ids = [];
    let contacts_ids = [];
    // Получим все компании
    let companies = await getCompanies(filters)
    if (companies && companies.length > 0) {
        for (let company of companies) {
            companies_ids.push(company.ID)
        }
        if (companies_ids.length > 0) {
            companies_ids = [... new Set(companies_ids)]
            // Получим все контакты для компаний
            contacts = await getContacts(companies_ids)
            for (let contact of contacts) {
                contacts_ids.push(contact.ID);
            }
            contacts_ids = [... new Set(contacts_ids)]
            // Получим все сделки для компаний
            dials = await getDials(companies_ids, filters)
            let dials_ids = []
            for (const dial of dials) {
                dials_ids.push(dial.ID)
            }
            // Получим все командировки для компаний
            business_trips = await getBissinessTrips(companies_ids, filters)
            // Получим все коммуникации для компаний
            const activitiesCompanies = await getActivitiesCompanies(companies_ids, filters)
            activities = [...activities, ...activitiesCompanies]
            // Получим все коммуникации для контактов
            const activitiesContacts = await getActivitiesContacts(contacts_ids, filters)
            activities = [...activities, ...activitiesContacts]
            // Получим все коммуникации для сделок
            const activitiesDials = await getActivitiesDials(dials_ids, filters)
            activities = [...activities, ...activitiesDials]
            // Получим все группы для компаний
            const groups = await getFieldGroup(443)
            // Получим все коммерческие предложения для компаний
            const offers = await getCommercialOffers(companies_ids, filters)
            // Получим все задачи для компаний
            const tasks = await getTasks(companies_ids, filters)
            // Сформируем массив для таблицы
            for (let company of companies) {
                let group = null, meetings = 0, BisinessTrips = 0, countTasks = 0
                let summaDials = 0, countDials = 0, countOffers = 0, summaOffers = 0
                const emails = { inbox: 0, outbox: 0 }
                const calls = { inbox: 0, outbox: 0 }
                let lastDateActivities = null
                let lastDateBisinessTrips = null
                let lastDateDials = null
                let lastDateOffers = null
                let lastDateTasks = null
                let employee = ''

                if (company.UF_CRM_1629897114) {
                    const groupRes = groups.filter((group) => group.id == company.UF_CRM_1629897114)
                    group = groupRes && groupRes.length > 0 ? groupRes[0] : null
                }

                for (const activity of activities) {
                    // Значения: 1 - входящее, 2 - исходящее
                    if (activity.OWNER_TYPE_ID == 4 && activity.OWNER_ID == company.ID) {
                        if (!lastDateActivities || (lastDateActivities && new Date(dayjs(lastDateActivities).format('DD/MM/YYYY')) < new Date(activity.CREATED))) {
                            lastDateActivities = dayjs(activity.CREATED).format('DD.MM.YYYY')
                        }
                        if (activity.PROVIDER_TYPE_ID == 'EMAIL') {
                            activity.DIRECTION == 1 ? emails.inbox++ : emails.outbox++
                        } else if (activity.PROVIDER_TYPE_ID == 'MEETING') {
                            meetings++
                        } else if (activity.PROVIDER_TYPE_ID == 'CALL') {
                            activity.DIRECTION == 1 ? calls.inbox++ : calls.outbox++
                        }
                    } else if (activity.OWNER_TYPE_ID == 3) {
                        for (const contact of contacts) {
                            if (contact.COMPANY_ID == company.ID && activity.OWNER_ID == contact.ID) {
                                if (!lastDateActivities || (lastDateActivities && new Date(dayjs(lastDateActivities).format('DD/MM/YYYY')) < new Date(activity.CREATED))) {
                                    lastDateActivities = dayjs(activity.CREATED).format('DD.MM.YYYY')
                                }
                                if (activity.PROVIDER_TYPE_ID == 'EMAIL') {
                                    activity.DIRECTION == 1 ? emails.inbox++ : emails.outbox++
                                } else if (activity.PROVIDER_TYPE_ID == 'MEETING') {
                                    meetings++
                                } else if (activity.PROVIDER_TYPE_ID == 'CALL') {
                                    activity.DIRECTION == 1 ? calls.inbox++ : calls.outbox++
                                }
                            }
                        }
                    } else if (activity.OWNER_TYPE_ID == 2) {
                        for (const dial of dials) {
                            if (dial.COMPANY_ID == company.ID && activity.OWNER_ID == dial.ID) {
                                if (!lastDateActivities || (lastDateActivities && new Date(dayjs(lastDateActivities).format('DD/MM/YYYY')) < new Date(activity.CREATED))) {
                                    lastDateActivities = dayjs(activity.CREATED).format('DD.MM.YYYY')
                                }
                                if (activity.PROVIDER_TYPE_ID == 'EMAIL') {
                                    activity.DIRECTION == 1 ? emails.inbox++ : emails.outbox++
                                } else if (activity.PROVIDER_TYPE_ID == 'MEETING') {
                                    meetings++
                                } else if (activity.PROVIDER_TYPE_ID == 'CALL') {
                                    activity.DIRECTION == 1 ? calls.inbox++ : calls.outbox++
                                }
                            }
                        }
                    }
                }

                for (const business_trip of business_trips) {
                    for (var key in business_trip.PROPERTY_375) {
                        if (business_trip.PROPERTY_375[key] == company.ID) {
                            BisinessTrips++
                            if ((!lastDateBisinessTrips && business_trip.PROPERTY_381_VALUE)
                                || (business_trip.PROPERTY_381_VALUE
                                    && lastDateBisinessTrips < business_trip.PROPERTY_381_VALUE.slice(0, -9)
                                )
                            ) {
                                lastDateBisinessTrips = business_trip.PROPERTY_381_VALUE.slice(0, -9)
                            }
                        }
                    }
                }

                for (const dial of dials) {
                    if (dial.COMPANY_ID == company.ID) {
                        countDials++
                        summaDials += parseInt(dial.OPPORTUNITY);
                        if (!lastDateDials || (lastDateDials && new Date(dayjs(lastDateDials).format('DD/MM/YYYY')) < new Date(dial.DATE_CREATE))) {
                            lastDateDials = dayjs(dial.DATE_CREATE).format('DD.MM.YYYY')
                        }
                    }
                }

                for (const offer of offers) {
                    if (offer.COMPANY_ID == company.ID) {
                        countOffers++
                        summaOffers += parseInt(offer.OPPORTUNITY);
                        if (!lastDateOffers || (lastDateOffers && new Date(dayjs(lastDateOffers).format('DD/MM/YYYY')) < new Date(offer.DATE_CREATE))) {
                            lastDateOffers = dayjs(offer.DATE_CREATE).format('DD.MM.YYYY')
                        }
                    }
                }

                if (tasks) {
                    for (const task of tasks) {
                        if (task.ufCrmTask.indexOf(`CO_${company.ID}`) != -1) {
                            countTasks++
                            if (!lastDateTasks || (lastDateTasks && new Date(dayjs(lastDateTasks).format('DD/MM/YYYY')) < new Date(task.createdDate))) {
                                lastDateTasks = dayjs(task.createdDate).format('DD.MM.YYYY')
                            }
                        }
                    }
                }

                for (const user of employees) {
                    if (user.id == company.ASSIGNED_BY_ID) {
                        employee = user.name
                    }
                }

                const itemRes = {
                    id: company.ID,
                    company: company.TITLE,
                    lastDateActivities: lastDateActivities ?? 'Отсутствует',
                    lastDateBisinessTrips: lastDateBisinessTrips ?? 'Отсутствует',
                    lastDateDials: lastDateDials ?? 'Отсутствует',
                    lastDateOffers: lastDateOffers ?? 'Отсутствует',
                    lastDateTasks: lastDateTasks ?? 'Отсутствует',
                    BisinessTrips,
                    group,
                    countDials,
                    meetings,
                    calls,
                    emails,
                    summaDials,
                    employee,
                    countOffers,
                    summaOffers,
                    countTasks
                }
                results.push(itemRes)
            }
            return results
        }
    }
}