import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import getResultData from '../services/getResultTableData';
import { GridLocaleText } from '../locales/tableGridLocale';
import { useLocalStorage } from '../hooks/useLocalStorage';

function getComunications(params) {
    return params.row.emails.inbox
        + params.row.emails.outbox
        + params.row.meetings
        + params.row.calls.inbox
        + params.row.calls.outbox;
}

function getGroupName(params) {
    return params.row.group ? params.row.group.name : ""
}

const columns = [
    { field: 'id', headerName: 'ИД', width: 70, type: 'number', },
    {
        field: 'company',
        headerName: 'Контрагент',
        width: 260,
        type: 'string',
        hideable: false,
        renderCell: (params) => {
            return (
                <a href={`https://vhz.bitrix24.ru/crm/company/details/${params.id}/`}
                    style={{ color: 'currentcolor', textDecoration: 'underline', overflow: 'auto', whiteSpace: 'normal' }}
                    target="_blank">{params.value}</a>
            )
        }
    },
    {
        field: 'groupName',
        headerName: 'Группа',
        width: 70,
        align: "center",
        type: 'string',
        renderCell: (params) => {
            return (
                <div style={{ color: params.row?.group?.color, fontSize: 16, fontWeight: 'bold' }}>{params.row?.group?.name}</div>
            )
        },
        valueGetter: getGroupName
    },
    {
        field: 'employee',
        headerName: 'Ответственный',
        width: 160,
        type: 'string',
        hideable: false
    },
    {
        field: 'comunication',
        headerName: 'Коммуникаций',
        width: 140,
        type: 'number',
        align: "center",
        valueGetter: getComunications
    },
    {
        field: 'inboxCalls',
        headerName: 'Исх. звонков',
        width: 100,
        type: 'number',
        align: "center",
        valueGetter: (params) => params.row.calls.inbox
    },
    {
        field: 'outboxCalls',
        headerName: 'Вх. звонков',
        width: 100,
        type: 'number',
        align: "center",
        valueGetter: (params) => params.row.calls.outbox
    },
    {
        field: 'inboxEmails',
        headerName: 'Вх. email',
        width: 100,
        type: 'number',
        align: "center",
        valueGetter: (params) => params.row.emails.inbox
    },
    {
        field: 'outboxEmails',
        headerName: 'Исх. email',
        width: 100,
        type: 'number',
        align: "center",
        valueGetter: (params) => params.row.emails.outbox
    },
    {
        field: 'meetings',
        headerName: 'Встреч',
        width: 100,
        type: 'number',
        align: "center"
    },
    {
        field: 'lastDateActivities',
        headerName: 'Дата п. общ.',
        width: 120,
        type: 'date'
    },
    {
        field: 'BisinessTrips',
        headerName: 'Командировок',
        width: 140,
        type: 'number',
        align: "center"
    },
    {
        field: 'lastDateBisinessTrips',
        headerName: 'Дата п. ком.',
        width: 120,
        type: 'date'
    },
    {
        field: 'countDials',
        headerName: 'Сделок',
        width: 80,
        type: 'number',
        align: "center"
    },
    {
        field: 'lastDateDials',
        headerName: 'Дата п. сделки',
        width: 140,
        type: 'date'
    },
    {
        field: 'summaDials',
        headerName: 'Сумма сделок',
        width: 140,
        type: 'number',
        align: "center"
    },
    {
        field: 'countOffers',
        headerName: 'Ком. пред.',
        width: 100,
        type: 'number',
        align: "center"
    },
    {
        field: 'lastDateOffers',
        headerName: 'Дата п. к. пред',
        width: 140,
        type: 'date'
    },
    {
        field: 'summaOffers',
        headerName: 'Сумма к. пред',
        width: 140,
        type: 'number',
        align: "center"
    },
    {
        field: 'countTasks',
        headerName: 'Задач',
        width: 100,
        type: 'number',
        align: "center"
    },
    {
        field: 'lastDateTasks',
        headerName: 'Дата п. задачи',
        width: 140,
        type: 'date'
    },
];
//lastDateOffers
export default function BasicTable({ filtersData, employees, loading, setLoading, isSendData }) {
    const [data, setData] = useState([])
    const [pageSize, setPageSize] = React.useState(20);
    const [filterModel, setFilterModel] = useLocalStorage({ items: [] }, 'filtersTable')
    const getDataTable = async () => {
        setLoading(true)
        await getResultData(filtersData, employees).then((response = []) => {
            console.log(response)
            setData(response)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (filtersData?.employees?.length > 0 && isSendData) {
            getDataTable()
        }
    }, [isSendData])
    //height: 490
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={data}
                loading={loading}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
                localeText={GridLocaleText}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 200}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            inboxCalls: false,
                            outboxCalls: false,
                            inboxEmails: false,
                            outboxEmails: false,
                            meetings: false,
                            lastDateActivities: false,
                            lastDateBisinessTrips: false,
                            lastDateDials: false,
                            lastDateOffers: false
                        },
                    },
                }}
                componentsProps={{
                    toolbar: {
                        csvOptions: {
                            allColumns: true,
                            delimiter: ';',
                            fileName: 'Аналитика по компаниям',
                            utf8WithBom: true
                        }
                    },
                    pagination: {
                        labelRowsPerPage: 'Строк на странице:'
                    }
                }}
                sx={{
                    '&.MuiDataGrid-root .MuiDataGrid-cell': { borderBottomColor: '#525c69' },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': { borderBottomColor: '#525c69' },
                    '&.MuiDataGrid-root .MuiDataGrid-footerContainer': { borderTopColor: '#525c69' },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': { color: '#525c69' },
                }}
            />
        </div>
    );
}