import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import MultipleSelect from './MultipleSelect';
import DatePicker from './DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { getDepartments, getEmployees } from '../services/getFiltersData';
import { useLocalStorage } from '../hooks/useLocalStorage';

export default function Header({ setFiltersData, setEmployees, employees, loading, setSendData }) {
    const [departments, setDepartments] = useState([])
    const [selectDepartments, setSelectDepartments] = useLocalStorage([], 'selectDepartments')
    const [selectEmployees, setSelectEmployees] = useState([])
    const [departmentLoading, setDepartmentLoading] = useState(false)
    const [employeeLoading, setEmployeeLoading] = useState(false)
    const [dateStart, setDateStart] = useState(dayjs().startOf('month'))
    const [dateFinish, setDateFinish] = useState(dayjs())

    const getDepartmentsData = (async () => {
        setDepartmentLoading(true)
        const response = await getDepartments()
        setDepartments(response)
        setDepartmentLoading(false)
    })

    const getEmployeesData = (async () => {
        setEmployeeLoading(true)
        setSelectEmployees([])
        const ids = []
        selectDepartments.forEach((item) => {
            ids.push(item.id)
        })
        const response = await getEmployees(ids)
        setEmployees(response)
        setEmployeeLoading(false)
    })

    const getFiltersData = () => {
        const filters = {
            departments: [],
            employees: [],
            dateStart: dayjs(dateStart).format('DD.MM.YYYY 00:00:01'),
            dateFinish: dayjs(dateFinish).format('DD.MM.YYYY 23:59:59')
        }
        selectDepartments.forEach((dep) => filters.departments.push(dep.id))
        selectEmployees.forEach((emp) => filters.employees.push(emp.id))
        setFiltersData(filters)
    }

    useEffect(() => {
        getDepartmentsData()
    }, [])

    useEffect(() => {
        if (selectDepartments.length > 0) {
            getEmployeesData()
        } else {
            setEmployees([])
            setSelectEmployees([])
        }
    }, [selectDepartments])

    useEffect(() => {
        getFiltersData()
    }, [selectDepartments, selectEmployees, dateStart, dateFinish])

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ pb: 2 }}
        >
            <MultipleSelect
                label='Выберите отдел'
                items={departments}
                setValue={setSelectDepartments}
                width={380}
                value={selectDepartments}
                loading={departmentLoading}
            />
            <MultipleSelect
                label='Выберите сотрудника'
                items={employees}
                setValue={setSelectEmployees}
                width={380}
                value={selectEmployees}
                loading={employeeLoading}
            />
            <DatePicker
                label="Период от"
                value={dateStart}
                setValue={setDateStart}
                minDate={dayjs().subtract(1, 'year')}
                maxDate={dateFinish}
            />
            <DatePicker
                label="Период до"
                value={dateFinish}
                setValue={setDateFinish}
                minDate={dateStart}
                maxDate={dayjs()}
            />
            <LoadingButton
                loading={loading}
                endIcon={<SendIcon />}
                variant="outlined"
                size="large"
                onClick={() => setSendData(true)}
            >Показать</LoadingButton>
        </Stack>
    );
}
