import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const Apps = () => (
    <Router >
        <Routes>
            <Route path="/" element={<App />} />
        </Routes>
    </Router >
);

root.render(<Apps />);