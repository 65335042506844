import { callMethod } from "./bitrix24ApiServices"

const getDepartments = async () => {
    const params = {
        sort: 'NAME',
        order: 'ASC',
        select: ["ID", "NAME"]
    };
    const response = await callMethod('department.get', params)
    if (response) {
        const result = []
        response.forEach(item => {
            result.push({
                id: item.ID,
                name: item.NAME
            })
        });
        return result
    } else {
        return []
    }
}

const getEmployees = async (ids = []) => {
    const params = {
        sort: 'NAME',
        order: 'ASC',
        filter: {
            UF_DEPARTMENT: ids,
            USER_TYPE: 'employee',
            ACTIVE: true
        },
        select: ["ID", "NAME", "LAST_NAME"]
    }
    const response = await callMethod('user.get', params)
    if (response) {
        const result = []
        response.forEach(item => {
            result.push({
                id: item.ID,
                name: `${item.NAME} ${item.LAST_NAME}`
            })
        });
        return result
    } else {
        return []
    }
}

export { getDepartments, getEmployees }