export const GridLocaleText = {
    // Root
    noRowsLabel: 'Нет данных',
    noResultsOverlayLabel: 'Результатов не найдено.',
    errorOverlayDefaultLabel: 'Произошла ошибка.',

    // Density selector toolbar button text
    toolbarDensity: 'Размер',
    toolbarDensityLabel: 'Размер',
    toolbarDensityCompact: 'Маленький',
    toolbarDensityStandard: 'Средний',
    toolbarDensityComfortable: 'Большой',

    // Columns selector toolbar button text
    toolbarColumns: 'Столбцы',
    toolbarColumnsLabel: 'Выберите столбцы',

    // Filters toolbar button text
    toolbarFilters: 'Фильтры',
    toolbarFiltersLabel: 'Показать фильтры',
    toolbarFiltersTooltipHide: 'Скрыть фильтры',
    toolbarFiltersTooltipShow: 'Показать фильтры',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} активных фильтров` : `${count} активных фильтров`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Поиск…',
    toolbarQuickFilterLabel: 'Поиск',
    toolbarQuickFilterDeleteIconLabel: 'Очистить',

    // Export selector toolbar button text
    toolbarExport: 'Экспорт',
    toolbarExportLabel: 'Экспорт',
    toolbarExportCSV: 'Скачать в CSV',
    toolbarExportPrint: 'Печать',
    toolbarExportExcel: 'Скачать в Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Поиск столбца',
    columnsPanelTextFieldPlaceholder: 'Название',
    columnsPanelDragIconLabel: 'Изменить порядок',
    columnsPanelShowAllButton: 'Показать все',
    columnsPanelHideAllButton: 'Скрыть все',

    // Filter panel text
    filterPanelAddFilter: 'Добавить фильтр',
    filterPanelDeleteIconLabel: 'Удалить',
    filterPanelLinkOperator: 'Логический оператор',
    filterPanelOperator: 'Оператор',
    filterPanelOperatorAnd: 'И',
    filterPanelOperatorOr: 'ИЛИ',
    filterPanelColumns: 'Столбцы',
    filterPanelInputLabel: 'Значение',
    filterPanelInputPlaceholder: 'Значение фильтра',

    // Filter operators text
    filterOperatorContains: 'содержит',
    filterOperatorEquals: 'равен',
    filterOperatorStartsWith: 'начинается с',
    filterOperatorEndsWith: 'заканчивается на',
    filterOperatorIs: 'равен',
    filterOperatorNot: 'не равен',
    filterOperatorAfter: 'больше чем',
    filterOperatorOnOrAfter: 'больше или равно',
    filterOperatorBefore: 'меньше чем',
    filterOperatorOnOrBefore: 'меньше или равно',
    filterOperatorIsEmpty: 'пустой',
    filterOperatorIsNotEmpty: 'не пустой',
    filterOperatorIsAnyOf: 'любой из',

    // Filter values text
    filterValueAny: 'любой',
    filterValueTrue: 'истина',
    filterValueFalse: 'лож',

    // Column menu text
    columnMenuLabel: 'Меню',
    columnMenuShowColumns: 'Показать столбцы',
    columnMenuFilter: 'Фильтр',
    columnMenuHideColumn: 'Скрыть',
    columnMenuUnsort: 'Любой выбор',
    columnMenuSortAsc: 'Сортировать по возрастанию',
    columnMenuSortDesc: 'Сортировать по уменьшению',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} активных фильтров` : `${count} активных фильтров`,
    columnHeaderFiltersLabel: 'Показать фильтр',
    columnHeaderSortIconLabel: 'Сортировка',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} выбрано строк`
            : `${count.toLocaleString()} выбрана строка`,

    // Total row amount footer text
    footerTotalRows: 'Всего строк:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} из ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox selection',
    checkboxSelectionSelectAllRows: 'Select all rows',
    checkboxSelectionUnselectAllRows: 'Unselect all rows',
    checkboxSelectionSelectRow: 'Select row',
    checkboxSelectionUnselectRow: 'Unselect row',

    // Boolean cell text
    booleanCellTrueLabel: 'да',
    booleanCellFalseLabel: 'нет',

    // Actions cell more text
    actionsCellMore: 'более',

    // Column pinning text
    pinToLeft: 'Pin to left',
    pinToRight: 'Pin to right',
    unpin: 'Unpin',

    // Tree Data
    treeDataGroupingHeaderName: 'Group',
    treeDataExpand: 'see children',
    treeDataCollapse: 'hide children',

    // Grouping columns
    groupingColumnHeaderName: 'Group',
    groupColumn: (name) => `Group by ${name}`,
    unGroupColumn: (name) => `Stop grouping by ${name}`,

    // Master/detail
    detailPanelToggle: 'Detail panel toggle',
    expandDetailPanel: 'Expand',
    collapseDetailPanel: 'Collapse',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Row reordering',

    // Aggregation
    aggregationMenuItemHeader: 'Aggregation',
    aggregationFunctionLabelSum: 'sum',
    aggregationFunctionLabelAvg: 'avg',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
};