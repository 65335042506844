import React, { useState, useEffect } from "react"
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Box } from "@mui/material"
import { grey } from '@mui/material/colors'
import Header from "./components/Header"
import Table from "./components/Table"

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [filtersData, setFiltersData] = useState({})
    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(false)
    const [isSendData, setSendData] = useState(false)

    useEffect(() => {
        if (loading) {
            setSendData(false)
        }
    }, [loading])

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                    ...(!prefersDarkMode
                        ? {
                            // palette values for light mode
                            primary: grey,
                            divider: grey[800],
                            background: {
                                default: grey[100],
                                paper: grey[200],
                            },
                            text: {
                                primary: grey[900],
                                secondary: grey[800],
                            }
                        }
                        : {
                            // palette values for dark mode
                            primary: grey,
                            divider: '#0d9488',
                            background: {
                                default: '#1f2937',
                                paper: '#354050',
                            },
                            text: {
                                primary: grey[100],
                                secondary: grey[300],
                            }
                        }),
                },
                components: {
                    MuiDataGrid: {
                        styleOverrides: {
                            root: {
                                borderColor: '#525c69',
                                borderTopColor: '#525c69',
                                borderBottomColor: '#525c69',
                                columnHeaders: {
                                    borderTopColor: '#525c69',
                                    borderBottomColor: '#525c69'
                                }
                            },
                        },
                    },
                }
            }),
        [prefersDarkMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ p: 2 }}>
                <Header
                    setFiltersData={setFiltersData}
                    setEmployees={setEmployees}
                    employees={employees}
                    loading={loading}
                    setSendData={setSendData}
                />
                <Table
                    filtersData={filtersData}
                    employees={employees}
                    loading={loading}
                    setLoading={setLoading}
                    isSendData={isSendData}
                />
            </Box>
        </ThemeProvider>
    )
}

export default App;